import styled from 'styled-components';
import { ContentSafeArea } from '../../components/globalComponents';

export const ContactContentSafeArea = styled(ContentSafeArea)`
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 2rem;
`
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  * {
    width: 100%;
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @media(max-width: 990px) {
    grid-template-columns: repeat(1, 1fr);
  }

  a {
    text-decoration: none;
  }
`

export const Card = styled.div`
  width: 100%;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);
  color: #fff;
  padding: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 1rem;
`

export const WhatsappCard = styled(Card)`
  background: linear-gradient(90deg, #1BD741 0%, #1EF84A 100%);
`
export const InstagramCard = styled(Card)`
  background: linear-gradient(90deg, #FF00FF 0%, #B100B1 100%);
`
export const EmailCard = styled(Card)`
  background: linear-gradient(90deg, #D4BF01 0%, #908200 100%);
`

export const TelephoneCard = styled(Card)`
  background: linear-gradient(90deg, #10BFCA 0%, #0D888F 100%);
`

export const TextRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  span {
    text-align: left;
    font-size: 1.5em;
    font-weight: 700;
  }

  a {
    text-align: left;
    font-size: 1.5em;
    font-weight: 600;
    text-decoration: none;
    color: #fff;

    :hover {
      color: #09595E;
    }
  }
`
