import React from 'react';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Whatsapp } from '@styled-icons/fa-brands';
import { OldPhone } from '@styled-icons/entypo/OldPhone';
import { EmailOutline } from '@styled-icons/evaicons-outline/EmailOutline';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import { CardsContainer, ContactContentSafeArea, TelephoneCard, TextRightSide, WhatsappCard, ImageContainer, EmailCard, InstagramCard } from './styles';

export default function Contato() {

  const { contactImages } = useStaticQuery(graphql`
    query {
      contactImages: allFile(filter: {relativeDirectory: {eq: "contact"}}) {
        edges {
          node {
            name
            childImageSharp {
              fluid: fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp
                }
            }
          }
        }
      }
    }
  `)

  return(
    <Layout>
      <SEO title="Contato" />
      <ContactContentSafeArea>

        <ImageContainer>
        {contactImages.edges.map(({node}) =>
          <Img
            fluid={node.childImageSharp.fluid}
          />
        )}
        </ImageContainer>

        <CardsContainer>

        <OutboundLink href="https://api.whatsapp.com/send?phone=5521964781982&text=Ol%C3%A1%2C%20gostaria%20de%20fazer%20um%20or%C3%A7amento" target="_blank" rel="noreferrer">
            <WhatsappCard>
              <Whatsapp style={{width: '30%'}}/>
              <TextRightSide>
              <span>Entre em contato via Whatsapp!</span>
              <span>(21) 96478-1982</span>
              </TextRightSide>
            </WhatsappCard>
          </OutboundLink>

          <TelephoneCard>
            <OldPhone style={{width: '30%'}}/>
            <TextRightSide>
              <span>Fale conosco:</span>
              <OutboundLink href="tel:212667-5995" target="_blank" rel="noreferrer">
               (21) 2667-5995
              </OutboundLink>
              <OutboundLink href="tel:212797-4613" target="_blank" rel="noreferrer">
               (21) 2797-4613
              </OutboundLink>
            </TextRightSide>
          </TelephoneCard>

          <OutboundLink href="mailto:orcamento@vivagraf.com.br" target="_blank" rel="noreferrer">
            <EmailCard>
              <EmailOutline style={{width: '30%'}}/>
              <TextRightSide>
                <span>Peça um orçamento via e-mail!</span>
                <span style={{fontSize: '1em'}}>orcamento@vivagraf.com.br</span>
              </TextRightSide>
            </EmailCard>
          </OutboundLink>

          <OutboundLink href="https://www.instagram.com/vivagraf/" target="_blank" rel="noreferrer">
            <InstagramCard>
              <Instagram style={{width: '30%'}}/>
              <TextRightSide>
                <span>Interaja conosco nas redes sociais!</span>
                <span>@vivagraf</span>
              </TextRightSide>
            </InstagramCard>
          </OutboundLink>

        </ CardsContainer>
      </ContactContentSafeArea>
    </Layout>
  )
}
